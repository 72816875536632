
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "cooperative-add-new-members",
  components: {
    ErrorMessage,
    Field,
    Form,
    Gender,
    GoBackButton,
  },
  data() {
    return {
      passwordVisible: false,
      displayShowPasswword: false,
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      emailAddress: "",
      phoneNumber: "",
      avatar: "",
      logo: "",
      yearOfEstablishment: "",
      password: "",
      confirmPassword: "",
      random: "",
      companyName: "",
      currentYear: new Date().getFullYear(),
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const underwriterDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      yearOfEstablishment: Yup.date()
        .required()
        .label("Year of establishment"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Confirm Password"),
    });

    const createUnderwriter = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send underwriter information request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.SUPERADMIN_UNDERWRITER_ROUTE, values, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Add New", ["Users", "Underwriters"]);
    });

    return {
      submitButton1,
      createUnderwriter,
      underwriterDetailsValidator,
    };
  },
  created() {
    //Set page title
    document.title = "Add New Underwriter | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    removeImage() {
      this.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.avatar = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    generatePassword() {
      const passwordField = document.getElementById(
        "password"
      ) as HTMLInputElement;
      const confirmPasswordField = document.getElementById(
        "confirm-password"
      ) as HTMLInputElement;

      const generatedPassword = variables.generatePassword();
      passwordField.value = generatedPassword;
      confirmPasswordField.value = generatedPassword;
      this.password = generatedPassword;
      this.confirmPassword = generatedPassword;
    },
    logoImage(event) {
      const selectedImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.logo = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    togglePasswordVisibility() {
      this.displayShowPasswword = this.password ? true : false;
      this.passwordVisible = !this.passwordVisible;
    },
  },
});
